import { useEffect, useState } from "react";
import { useFrame } from "react-frame-component";

export const useLocalStorageState = (key, defaultValue) => {
  const { window } = useFrame();

  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = window.JSON.parse(
        window.localStorage.getItem(key) || String(defaultValue),
      );
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(key, window.JSON.stringify(value));
    } catch (error) {
      console.error("Could not write to localStorage:", error);
    }
  }, [window, value, key]);

  return [value, setValue];
};
