interface LessonArgs {
  spaceSlug: string;
  sectionId: string | number;
  lessonId: string | number;
}

interface ShowLessonCommentArgs extends LessonArgs {
  commentId: string;
}

export const coursePaths = {
  showLesson: ({ spaceSlug, sectionId, lessonId }: LessonArgs) =>
    `/c/${spaceSlug}/sections/${sectionId}/lessons/${lessonId}`,
  showLessonComment: ({
    spaceSlug,
    sectionId,
    lessonId,
    commentId,
  }: ShowLessonCommentArgs) =>
    `/c/${spaceSlug}/sections/${sectionId}/lessons/${lessonId}#message_${commentId}`,
  editLesson: ({ spaceSlug, sectionId, lessonId }: LessonArgs) =>
    `/c/${spaceSlug}/edit-lesson/sections/${sectionId}/lessons/${lessonId}`,
};
