import { absolutePath } from "./absolutePath";

export const AccountSubscriptions = {
  update: (planId, planCouponId, source) => {
    const payload = {
      plan_id: planId,
      plan_coupon_id: planCouponId,
      ...(source && { source }),
    };
    return absolutePath("account_subscriptions", payload);
  },
  renew: planId =>
    absolutePath("account_subscriptions/renew", { plan_id: planId }),
  canDowngrade: planId =>
    absolutePath("account_subscriptions/can_downgrade", { plan_id: planId }),
};
