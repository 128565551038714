interface WorkflowIdArgs {
  workflowId: string;
}

export const workflowsSettingsPaths = {
  index: () => `/settings/workflows`,
  history: () => `/settings/workflows/history`,
  history_detail: ({ workflowId }: WorkflowIdArgs) =>
    `/settings/workflows/history/${workflowId}`,
  new: () => `/settings/workflows/new`,
  select_type: () => `/settings/workflows/new/select_type`,
  dynamic: ({ workflowId }: WorkflowIdArgs) =>
    `/settings/workflows/new/dynamic/${workflowId}`,
  oneTime: ({ workflowId }: WorkflowIdArgs) =>
    `/settings/workflows/new/one_time/${workflowId}`,
  scheduled: ({ workflowId }: WorkflowIdArgs) =>
    `/settings/workflows/new/scheduled/${workflowId}`,
  view: ({ workflowId }: WorkflowIdArgs) =>
    `/settings/workflows/view/${workflowId}`,
  runs: ({ workflowId }: WorkflowIdArgs) =>
    `/settings/workflows/view/${workflowId}/runs`,
  edit: ({ workflowId }: WorkflowIdArgs) =>
    `/settings/workflows/edit/${workflowId}`,
  duplicate: ({ workflowId }: WorkflowIdArgs) =>
    `/settings/workflows/${workflowId}/duplicate`,
};
