import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

const proration = ({ new_plan_id, plan_coupon_id }) =>
  reactQueryGet(internalApi.plans.proration({ new_plan_id, plan_coupon_id }));
const fetch = ({ tier = "", id = "", billing_currency = "", locale = "" }) =>
  reactQueryGet(
    internalApi.plans.fetch({ tier, id, billing_currency, locale }),
  );
const fetchPlans = ({ billing_cycle, billing_currency, locale }) =>
  reactQueryGet(
    internalApi.plans.fetchPlans({
      billing_cycle,
      billing_currency,
      locale,
    }),
  );

export const planApi = {
  proration,
  fetch,
  fetchPlans,
};
