const memberIdString = ":memberPublicId";

interface PathWithState {
  pathname: string;
  state?: {
    from: any;
  };
}

const buildAdminMemberEditPath = (
  path: string,
  prevLocation: any,
): PathWithState => {
  const prevLocationObject = prevLocation
    ? { state: { from: prevLocation } }
    : {};

  return {
    pathname: path,
    ...prevLocationObject,
  };
};

export const adminMemberEditPaths = {
  index: (memberPublicId: string = memberIdString) =>
    `/admin/members/${memberPublicId}`,
  info: (memberPublicId: string = memberIdString) =>
    `/admin/members/${memberPublicId}/info`,
  access: (memberPublicId: string = memberIdString) =>
    `/admin/members/${memberPublicId}/access`,
  billing: (memberPublicId: string = memberIdString) =>
    `/admin/members/${memberPublicId}/billing`,
  activityLogs: (memberPublicId: string = memberIdString) =>
    `/admin/members/${memberPublicId}/activity_logs`,
};

export const adminMemberEditPathsWithState = {
  index: (memberPublicId: string = memberIdString, prevLocation: any) =>
    buildAdminMemberEditPath(
      adminMemberEditPaths.index(memberPublicId),
      prevLocation,
    ),
  info: (memberPublicId: string = memberIdString, prevLocation: any) =>
    buildAdminMemberEditPath(
      adminMemberEditPaths.info(memberPublicId),
      prevLocation,
    ),
  access: (memberPublicId: string = memberIdString, prevLocation: any) =>
    buildAdminMemberEditPath(
      adminMemberEditPaths.access(memberPublicId),
      prevLocation,
    ),
  billing: (memberPublicId: string = memberIdString, prevLocation: any) =>
    buildAdminMemberEditPath(
      adminMemberEditPaths.billing(memberPublicId),
      prevLocation,
    ),
  activityLogs: (memberPublicId: string = memberIdString, prevLocation: any) =>
    buildAdminMemberEditPath(
      adminMemberEditPaths.activityLogs(memberPublicId),
      prevLocation,
    ),
};
