import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import { isFunction } from "lodash";
import { useLikeClickHandler } from "@/react/components/PostsV3/useLikeClickHandler";
import { usePostDetails } from "@circle-react/contexts/postsDetailsContext";
import {
  isUsingTiptapEditor,
  isUsingTrixEditor,
} from "@circle-react/helpers/postHelpers";

export type UsedInOptions =
  | "home"
  | "full-post-modal"
  | "mobile-full-carousel-modal"
  | "minimal-post-modal"
  | undefined;

export interface CurrentPostContextObject {
  record?: any;
  onPostChange?: (post: any) => void;
  onLikeToggle?: (event: any) => Promise<void>;
  refetchPostDetails?: () => void;
  onPostFollow?: (updates: any) => void;
  updatePostDetailsQueryCache?: (updates: any) => void;
  helpers?: {
    postForTrixEditor: () => any;
    eventForEdit: () => any;
    isTiptapPost: boolean;
    isTrixPost: boolean;
  };
  isSubmitEnabled?: boolean;
  setIsSubmitEnabled?: (value: boolean) => void;
  usedIn?: UsedInOptions;
  isUsedInMinimalTipTapViewModal?: boolean;
  isUpdatingCardViewThumbnail?: boolean;
  setIsUpdatingCardViewThumbnail?: (value: boolean) => void;
}

export interface CurrentPostContextProps {
  post?: any;
  onPostChange?: (post: any) => void;
  usedIn?: UsedInOptions;
  children: ReactNode | ((value: CurrentPostContextObject) => ReactNode);
}

const initialState = {};
const CurrentPostContext =
  createContext<CurrentPostContextObject>(initialState);
CurrentPostContext.displayName = "CurrentPostContext";

export const CurrentPostContextProvider = ({
  post = {},
  usedIn,
  onPostChange,
  children,
}: CurrentPostContextProps) => {
  //Note: Body for display and body to be sent to trix editor for edit have different values
  const {
    postDetails = {},
    onLikeToggle,
    refetchPostDetails,
    updatePostDetailsQueryCache,
  } = usePostDetails(post.id);

  const postWithPostDetails = { ...post, ...postDetails };
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  const [isUpdatingCardViewThumbnail, setIsUpdatingCardViewThumbnail] =
    useState(false);

  const revertTheUpdate = () => {
    isFunction(onLikeToggle) &&
      onLikeToggle(postDetails.is_liked_by_current_user);
  };

  const likeClickHandler = useLikeClickHandler({
    post: postWithPostDetails,
    onFailure: revertTheUpdate,
  });

  const _onLikeToggle = async (event: any) => {
    if (!postDetails.post_details_loaded) {
      return;
    }
    isFunction(onLikeToggle) &&
      onLikeToggle(!postDetails.is_liked_by_current_user);
    return await likeClickHandler(event);
  };

  const onPostFollow = (updates: any) => {
    isFunction(updatePostDetailsQueryCache) &&
      updatePostDetailsQueryCache(updates);
  };

  postWithPostDetails.sample_user_likes_community_members ||= [];

  const postForTrixEditor = () => ({
    ...postWithPostDetails,
    body: postWithPostDetails.body_for_editor,
  });

  const eventForEdit = () => {
    const { event_setting_attributes } = postWithPostDetails;
    // TODO: Remove this logic when (if) location_type comes from the backend
    const { in_person_location } = event_setting_attributes || {};
    const location_type = in_person_location ? ["in_person"] : ["virtual"];
    return {
      ...postForTrixEditor(),
      location_type,
    };
  };

  const isTiptapPost = isUsingTiptapEditor(postWithPostDetails);
  const isTrixPost = isUsingTrixEditor(postWithPostDetails);
  const isUsedInMinimalTipTapViewModal = usedIn === "minimal-post-modal";

  const value: CurrentPostContextObject = {
    record: postWithPostDetails,
    onPostChange: onPostChange,
    onLikeToggle: _onLikeToggle,
    refetchPostDetails: refetchPostDetails,
    onPostFollow: onPostFollow,
    updatePostDetailsQueryCache: updatePostDetailsQueryCache,
    helpers: {
      postForTrixEditor,
      eventForEdit,
      isTiptapPost,
      isTrixPost,
    },
    isSubmitEnabled,
    setIsSubmitEnabled,
    usedIn,
    isUsedInMinimalTipTapViewModal,
    isUpdatingCardViewThumbnail,
    setIsUpdatingCardViewThumbnail,
  };

  return (
    <CurrentPostContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </CurrentPostContext.Provider>
  );
};

export const useCurrentPostContext = () => useContext(CurrentPostContext);
