import { fetchRequest } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const show = ({ spaceId, postSlug }) =>
  fetchRequest(internalApi.posts.show({ spaceId, postSlug }), {
    method: "GET",
  });

const create = ({ post, formData }) =>
  fetchRequest(internalApi.posts.create({ spaceId: post.space_id }), {
    method: "POST",
    body: JSON.stringify({ post: formData }),
  });

const update = ({ post, formData }) =>
  fetchRequest(
    internalApi.posts.update({ spaceId: post.space_id, postSlug: post.slug }),
    {
      method: "PATCH",
      body: JSON.stringify({ post: formData }),
    },
  );

const destroy = ({ post }) =>
  fetchRequest(
    internalApi.posts.update({ spaceId: post.space_id, postSlug: post.slug }),
    {
      method: "DELETE",
      forReactQuery: true,
    },
  );

const hideFromFeaturedAreas = ({ post }) =>
  fetchRequest(
    internalApi.posts.featuredAreasVisibility.destroy({
      postSlug: post.slug,
      spaceId: post.space_id,
    }),
    {
      method: "DELETE",
      forReactQuery: true,
    },
  );

const showInFeaturedAreas = ({ post }) =>
  fetchRequest(
    internalApi.posts.featuredAreasVisibility.create({
      postSlug: post.slug,
      spaceId: post.space_id,
    }),
    {
      method: "POST",
      forReactQuery: true,
    },
  );

const toggleEditorType = ({ post }) =>
  fetchRequest(
    internalApi.posts.toggleEditorType({
      postId: post.id,
    }),
    {
      method: "POST",
      forReactQuery: true,
    },
  );

const updateKnowledgeBasePost = ({ post }) =>
  fetchRequest(
    internalApi.posts.updateKnowledgeBasePost({
      postId: post.id,
    }),
    {
      method: "PATCH",
    },
  );

const pin = ({ post, formData }) =>
  fetchRequest(
    internalApi.posts.pin({ spaceId: post.space_id, postSlug: post.slug }),
    {
      method: "PATCH",
      body: JSON.stringify({ post: formData }),
      forReactQuery: true,
    },
  );

const createOrUpdate = ({ post, formData }) =>
  post.id ? update({ post, formData }) : create({ post, formData });

export const postApi = {
  createOrUpdate,
  show,
  destroy,
  pin,
  hideFromFeaturedAreas,
  showInFeaturedAreas,
  toggleEditorType,
  updateKnowledgeBasePost,
};
