import { useEffect, useMemo } from "react";
import { useFrame } from "react-frame-component";
import type { ThemeObject } from "@circle-react/types/Theme";
import { APPEARANCE } from "../themes";
import { updateTheme } from "../updateTheme";
import { useLocalStorageAppearance } from "./useLocalStorageAppearance";

export const useThemeLocalStorage = (
  {
    default_appearance = APPEARANCE.LIGHT,
    custom_colors = {},
    current_theme = "default",
    is_custom = false,
    brand_color = {
      light: "",
      dark: "",
    },
    brand_text_color = {
      light: "",
      dark: "",
    },
  }: ThemeObject,
  selector = "",
) => {
  const { document } = useFrame();
  const { documentElement } = document ?? {};
  const {
    currentAppearance,
    setAppearance,
    toggleAppearance,
    hasLocalAppearance,
    isDarkAppearance,
    isLightAppearance,
  } = useLocalStorageAppearance({ defaultAppearance: default_appearance });

  const appearance = hasLocalAppearance
    ? currentAppearance
    : default_appearance;

  useEffect(() => {
    updateTheme({
      theme: {
        current_appearance: appearance,
        custom_colors,
        current_theme,
        is_custom,
        brand_color,
        brand_text_color,
      },
      documentElement: documentElement,
      selector,
    });
  }, [
    brand_color,
    brand_text_color,
    appearance,
    current_theme,
    custom_colors,
    documentElement,
    is_custom,
    selector,
  ]);

  return useMemo(
    () => ({
      currentTheme: current_theme,
      currentAppearance: appearance,
      isDarkAppearance,
      isLightAppearance,
      isCustomTheme: is_custom,
      customColors: custom_colors,
      brandColor: brand_color,
      brandTextColor: brand_text_color,
      toggleAppearance,
      setAppearance,
    }),
    [
      current_theme,
      appearance,
      isDarkAppearance,
      isLightAppearance,
      is_custom,
      custom_colors,
      brand_color,
      brand_text_color,
      toggleAppearance,
      setAppearance,
    ],
  );
};
