import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { workflows } from "@circle-react/helpers/urlHelpers/internalApi/workflows";
import type { Params } from "@circle-react/helpers/urlHelpers/toQueryString";

interface ParamsProps {
  params?: Params;
}

interface TaskIdProps {
  params?: Params;
  taskId: string;
}

interface BodyParamsProps {
  params?: Params;
  body?: any;
}

interface BodyProps {
  params?: Params;
  body?: any;
}

interface IdProps {
  id: string;
  params?: Params;
}

interface IdBodyProps {
  body?: any;
  id: string;
  params?: Params;
}

interface WorkflowIdBodyProps {
  body?: any;
  params?: Params;
  workflowId: string;
}

interface WorkflowIdIdBodyProps {
  body?: any;
  id: string;
  params?: Params;
  workflowId: string;
}

interface WorkflowIdIdProps {
  id: string;
  params?: Params;
  workflowId: string;
}

interface WorkflowIdProps {
  params?: Params;
  workflowId: string;
}

interface WorkflowIdRunIdProps {
  params?: Params;
  runId: string;
  workflowId: string;
}

interface TemplateIdProps {
  params?: Params;
  templateId: string;
}

const list = ({ params = {} }: ParamsProps) =>
  reactQueryGet(workflows.list({ params }));

const listHistory = ({ params = {} }: ParamsProps) =>
  reactQueryGet(workflows.tasks({ params }));

const taskDetail = ({ taskId, params = {} }: TaskIdProps) =>
  reactQueryGet(workflows.taskDetail({ params, taskId }));

const create = ({ params = {}, body = {} }: BodyParamsProps) =>
  reactQueryPost(workflows.create({ params }), body);

const fetch = ({ id, params = {} }: IdProps) =>
  reactQueryGet(workflows.fetch({ id, params }));

const update = ({ id, params = {}, body = {} }: IdBodyProps) =>
  reactQueryPut(workflows.update({ id, params }), body);

const activate = ({ id, params = {} }: IdProps) =>
  reactQueryPut(workflows.activate({ id, params }));

const inactivate = ({ id, params = {} }: IdProps) =>
  reactQueryPut(workflows.inactivate({ id, params }));

const createTrigger = ({
  workflowId,
  params = {},
  body = {},
}: WorkflowIdBodyProps) =>
  reactQueryPost(workflows.createTrigger({ workflowId, params }), body);

const updateTrigger = ({
  workflowId,
  id,
  params = {},
  body = {},
}: WorkflowIdIdBodyProps) =>
  reactQueryPut(workflows.updateTrigger({ workflowId, id, params }), body);

const deleteTrigger = ({ workflowId, id, params = {} }: WorkflowIdIdProps) =>
  reactQueryDelete(workflows.deleteTrigger({ workflowId, id, params }));

const createRun = ({ workflowId, params = {} }: WorkflowIdProps) =>
  reactQueryPost(workflows.createRun({ workflowId, params }));

const fetchRuns = ({ workflowId, params = {} }: WorkflowIdProps) =>
  reactQueryGet(workflows.fetchRuns({ workflowId, params }));

const fetchRun = ({ workflowId, runId, params = {} }: WorkflowIdRunIdProps) =>
  reactQueryGet(workflows.fetchRun({ workflowId, runId, params }));

const cancelRun = ({ workflowId, runId, params = {} }: WorkflowIdRunIdProps) =>
  reactQueryPut(workflows.cancelRun({ workflowId, runId, params }));

const fetchTasksInRun = ({
  workflowId,
  runId,
  params = {},
}: WorkflowIdRunIdProps) =>
  reactQueryGet(workflows.fetchTasksInRun({ workflowId, runId, params }));

const createAudience = ({
  workflowId,
  params = {},
  body = {},
}: WorkflowIdBodyProps) =>
  reactQueryPost(workflows.createAudience({ workflowId, params }), body);

const updateAudience = ({
  workflowId,
  id,
  params = {},
  body = {},
}: WorkflowIdIdBodyProps) =>
  reactQueryPut(workflows.updateAudience({ workflowId, id, params }), body);

const createAction = ({
  workflowId,
  params = {},
  body = {},
}: WorkflowIdBodyProps) =>
  reactQueryPost(workflows.createAction({ workflowId, params }), body);

const updateAction = ({
  workflowId,
  id,
  params = {},
  body = {},
}: WorkflowIdIdBodyProps) =>
  reactQueryPut(workflows.updateAction({ workflowId, id, params }), body);

const deleteAction = ({ workflowId, id, params = {} }: WorkflowIdIdProps) =>
  reactQueryDelete(workflows.deleteAction({ workflowId, id, params }));

const upsertActions = ({
  workflowId,
  params = {},
  body = {},
}: WorkflowIdBodyProps) =>
  reactQueryPost(workflows.upsertActions({ workflowId, params }), body);

const createOneTimeSchedule = ({
  workflowId,
  params = {},
  body = {},
}: WorkflowIdBodyProps) =>
  reactQueryPost(workflows.createOneTimeSchedule({ workflowId, params }), body);

const createRecurringSchedule = ({
  workflowId,
  params = {},
  body = {},
}: WorkflowIdBodyProps) =>
  reactQueryPost(
    workflows.createRecurringSchedule({ workflowId, params }),
    body,
  );

const updateOneTimeSchedule = ({
  workflowId,
  id,
  params = {},
  body = {},
}: WorkflowIdIdBodyProps) =>
  reactQueryPut(
    workflows.updateOneTimeSchedule({ workflowId, id, params }),
    body,
  );

const updateRecurringSchedule = ({
  workflowId,
  id,
  params = {},
  body = {},
}: WorkflowIdIdBodyProps) =>
  reactQueryPut(
    workflows.updateRecurringSchedule({ workflowId, id, params }),
    body,
  );

const reschedule = ({
  workflowId,
  id,
  params = {},
  body = {},
}: WorkflowIdIdBodyProps) =>
  reactQueryPut(workflows.reschedule({ workflowId, id, params }), body);

const templates = ({ params = {} }: ParamsProps) =>
  reactQueryGet(workflows.templates({ params }));

const fetchTemplate = ({ templateId, params = {} }: TemplateIdProps) =>
  reactQueryGet(workflows.fetchTemplate({ templateId, params }));

const createFromTemplate = ({ templateId, params = {} }: TemplateIdProps) =>
  reactQueryPost(workflows.createFromTemplate({ templateId, params }));

const archiveWorkflow = ({ id, params = {} }: IdProps) =>
  reactQueryPut(workflows.archive({ id, params }));

const duplicateWorkflow = ({ id, params = {} }: IdProps) =>
  reactQueryPut(workflows.duplicate({ id, params }));

const deleteWorkflow = ({ id, params = {} }: IdProps) =>
  reactQueryDelete(workflows.delete({ id, params }));

const fetchCommunityCurrentUsageStats = ({ params = {} } = {}) =>
  reactQueryGet(workflows.fetchCommunityCurrentUsageStats({ params }));

const fetchPreviewAttributes = ({ params = {} }: ParamsProps) =>
  reactQueryGet(workflows.previewMessages.previewAttributes({ params }));

const fetchInterpolatedPreviewMessage = ({ body = {} }: BodyProps) =>
  reactQueryPost(workflows.fetchInterpolatedMessage({}), body);

const testWebhook = ({ body = {} }: BodyProps) =>
  reactQueryPost(workflows.testWebhook({}), body);

export const workflowsApi = {
  list,
  listHistory,
  taskDetail,
  create,
  update,
  fetch,
  activate,
  inactivate,
  createTrigger,
  updateTrigger,
  deleteTrigger,
  createRun,
  fetchRuns,
  fetchRun,
  fetchTasksInRun,
  createAudience,
  updateAudience,
  createAction,
  updateAction,
  deleteAction,
  upsertActions,
  createOneTimeSchedule,
  updateOneTimeSchedule,
  createRecurringSchedule,
  updateRecurringSchedule,
  reschedule,
  templates,
  fetchTemplate,
  createFromTemplate,
  archiveWorkflow,
  deleteWorkflow,
  fetchCommunityCurrentUsageStats,
  duplicateWorkflow,
  fetchPreviewAttributes,
  fetchInterpolatedPreviewMessage,
  testWebhook,
  cancelRun,
};
