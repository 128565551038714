import { accessGroupMembers } from "./access_groups/accessGroupMembers";
import { accessGroups } from "./access_groups/accessGroups";
import { communityMemberPaymentMethods } from "./communityMemberPaymentMethods";
import { paywalls } from "./paywalls";
import { search } from "./search";

export const admin = {
  search,
  paywalls,
  communityMemberPaymentMethods,
  accessGroups,
  accessGroupMembers,
};
