import { useContext } from "react";
import { createContext } from "react";

export const USAGE_SECTIONS = {
  USER: "user",
  ADMIN: "admin",
};

/**
 * For adding customised UI between usage contexts.
 * An example would be the buttons - circle branded in admin usage contexts and primary in user contexts.
 */
export const UsageContext = createContext({
  section: USAGE_SECTIONS.USER,
});

UsageContext.displayName = "UsageContext";

export const useUsageContext = () => useContext(UsageContext);
