import { absolutePath } from "./absolutePath";

export const plans = {
  proration: ({ new_plan_id, plan_coupon_id = "" }) =>
    absolutePath("plans/prorations", {
      new_plan_id,
      plan_coupon_id,
    }),
  fetch: ({ tier = "", id = "", billing_currency = "", locale = "" }) => {
    const params = tier ? { tier } : { id };
    if (billing_currency) {
      params.billing_currency = billing_currency;
    }
    if (locale) {
      params.locale = locale;
    }
    return absolutePath("plan", params);
  },
  fetchPlans: ({ billing_cycle, billing_currency, locale } = {}) => {
    const queryParams = {
      ...(billing_cycle && { billing_cycle }),
      ...(billing_currency && { billing_currency }),
      ...(locale && { locale }),
    };
    return absolutePath("plans", queryParams);
  },
};
