import type { ReactNode } from "react";
import {
  useIsCustomizeCommunityAvailable,
  useIsDarkModeBeta,
} from "@circle-react/hooks/useIsCustomizeCommunity";
import type { ThemeObject } from "@circle-react/types/Theme";
import type { ThemeContextProps } from "../ThemeContext";
import { ThemeContext } from "../ThemeContext";
import { APPEARANCE } from "../themes";
import { useThemeLocalStorage } from "./useThemeLocalStorage";

export interface ThemeProviderProps {
  children: ReactNode;
  theme?: ThemeObject;
  selector?: string;
}

const emptyTheme: ThemeObject = {
  default_appearance: APPEARANCE.LIGHT,
  is_custom: false,
  current_theme: "default",
  brand_color: {
    light: "",
    dark: "",
  },
  brand_text_color: {
    light: "",
    dark: "",
  },
};

export const ThemeProvider = ({
  children,
  theme = emptyTheme,
  selector,
}: ThemeProviderProps) => {
  const isCustomizeCommunity: boolean = useIsCustomizeCommunityAvailable();
  const isDarkModeBeta: boolean = useIsDarkModeBeta();

  const forcedDefaultTheme: ThemeObject = {
    ...theme,
    current_theme: "default",
    is_custom: false,
    ...(!isDarkModeBeta && {
      default_appearance: APPEARANCE.LIGHT,
    }),
  };

  const chosenTheme: ThemeObject = isCustomizeCommunity
    ? theme
    : forcedDefaultTheme;
  const themeObject: ThemeContextProps = useThemeLocalStorage(
    chosenTheme,
    selector,
  );

  return (
    <ThemeContext.Provider value={themeObject}>
      {children}
    </ThemeContext.Provider>
  );
};
