import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import { workflows } from "@circle-react/helpers/urlHelpers/internalApi/workflows";
import { fetchRequest } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const create = ({ formData, ...fetchRequestOptions }: any) => {
  const { space_id } = formData;
  return fetchRequest(internalApi.events.create({ spaceId: space_id }), {
    method: "POST",
    body: JSON.stringify({ event: formData }),
    ...fetchRequestOptions,
  });
};

const update = ({ event, formData, ...fetchRequestOptions }: any) => {
  const { slug, space_id } = event;
  return fetchRequest(internalApi.events.update({ slug, spaceId: space_id }), {
    method: "PATCH",
    body: JSON.stringify({ event: formData }),
    ...fetchRequestOptions,
  });
};

const destroy = ({ event, params = {} }: any) => {
  const { slug, space_id: spaceId } = event;
  return fetchRequest(internalApi.events.destroy({ slug, spaceId, params }), {
    method: "DELETE",
  });
};

const rsvp = ({ event, params = {} }: any) => {
  const { slug, space_id: spaceId } = event;
  return fetchRequest(internalApi.events.attendees({ slug, spaceId, params }), {
    method: "POST",
    forReactQuery: true,
  });
};

const unRsvp = ({ event }: any) => {
  const { slug, space_id: spaceId } = event;
  return fetchRequest(internalApi.events.attendees({ slug, spaceId }), {
    method: "DELETE",
    forReactQuery: true,
  });
};

const searchCommunityMembers = ({ event, params }: any) => {
  const { id: eventId } = event;
  return reactQueryGet(
    internalApi.eventAttendees.searchCommunityMembers({ eventId, params }),
  );
};

const downloadCSV = ({ event }: any) => {
  const { slug, space_id: spaceId } = event;
  return reactQueryGet(internalApi.eventAttendees.exportCsv({ spaceId, slug }));
};

const bulkRsvp = ({ event, params = {} }: any) => {
  const { slug, space_id: spaceId } = event;
  const { community_member_ids, invited_entities_ids, role } = params;
  return reactQueryPut(internalApi.eventAttendees.bulkRsvp({ slug, spaceId }), {
    community_member_ids,
    invited_entities_ids,
    role,
  });
};

const fetchRecurring = ({ event }: any) => {
  const { slug, space_id: spaceId } = event;
  return reactQueryGet(internalApi.recurringEvents.index({ slug, spaceId }));
};

const recurringRsvp = ({ event, params = {} }: any) => {
  const { slug, space_id: spaceId } = event;
  const { eventIds } = params;
  return reactQueryPut(internalApi.recurringEvents.rsvp({ slug, spaceId }), {
    ...params,
    event_ids: eventIds,
  });
};

const recurringUnRsvp = ({ event, params }: any) => {
  const { slug, space_id: spaceId } = event;
  return reactQueryDelete(
    internalApi.eventAttendees.index({
      slug,
      spaceId,
      params: {
        unrsvp_mode: params,
      },
    }),
  );
};

const createLiveRoomForEvent = ({ event }: any) => {
  const { id } = event;
  return fetchRequest(internalApi.eventLiveStreams.create(), {
    method: "POST",
    body: JSON.stringify({ event_id: id }),
    forReactQuery: true,
  });
};

const removeAttendeeById = ({ event, attendeeId }: any) => {
  const { slug, space_id: spaceId } = event;

  return fetchRequest(
    internalApi.eventAttendees.destroyById({
      slug,
      spaceId,
      attendeeId,
    }),
    {
      method: "DELETE",
    },
  );
};

const pin = ({ event, formData }: any) =>
  fetchRequest(
    internalApi.events.pin({ spaceId: event.space_id, slug: event.slug }),
    {
      method: "PATCH",
      body: JSON.stringify({ event: formData }),
    },
  );

const fetchUpcomingEvents = ({ params = {} }) =>
  reactQueryGet(internalApi.workflowsUpcomingEvents.index(params));

const fetchEvent = ({ id, params = {} }: any) =>
  reactQueryGet(workflows.events.show({ id: id, params: params }));

const listEvents = ({ params = {} }) =>
  reactQueryGet(workflows.events.index({ params: params }));

export const eventApi = {
  create,
  update,
  destroy,
  fetchEvent,
  pin,
  rsvp,
  unRsvp,
  searchCommunityMembers,
  downloadCSV,
  bulkRsvp,
  fetchRecurring,
  recurringRsvp,
  recurringUnRsvp,
  createLiveRoomForEvent,
  removeAttendeeById,
  fetchUpcomingEvents,
  listEvents,
};
