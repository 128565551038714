import { useMemo } from "react";
import PropTypes from "prop-types";
import { USAGE_SECTIONS, UsageContext } from "./UsageContext";

export const UsageContextProvider = ({ section, children }) => {
  const value = useMemo(() => {
    const isAdminUsageContext = section === USAGE_SECTIONS.ADMIN;
    return {
      section,
      isAdminUsageContext,
    };
  }, [section]);

  return (
    <UsageContext.Provider value={value}>{children}</UsageContext.Provider>
  );
};

UsageContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  section: PropTypes.oneOf(Object.values(USAGE_SECTIONS)),
};
