import { useCallback, useEffect } from "react";
import { useLocalStorageState } from "@circle-react/hooks/utils/useLocalStorageState";
import { APPEARANCE } from "../themes";

export interface UseLocalStorageAppearanceProps {
  defaultAppearance?: (typeof APPEARANCE)[keyof typeof APPEARANCE];
}

export const useLocalStorageAppearance = ({
  defaultAppearance = APPEARANCE.LIGHT,
}: UseLocalStorageAppearanceProps) => {
  const [currentAppearance, setCurrentAppearance] = useLocalStorageState(
    "current_appearance",
    "",
  );

  const setAppearance = useCallback(
    (value: string) => {
      if (value === APPEARANCE.LIGHT || value === APPEARANCE.DARK) {
        setCurrentAppearance(value);
      }
    },
    [setCurrentAppearance],
  );

  useEffect(() => {
    if (window?.webview && !window.webview.setAppearance) {
      window.webview.setAppearance = setAppearance;
    }
  }, [setAppearance]);

  const toggleAppearance = () => {
    if (currentAppearance) {
      if (currentAppearance === APPEARANCE.LIGHT) {
        setCurrentAppearance(APPEARANCE.DARK);
      }
      if (currentAppearance === APPEARANCE.DARK) {
        setCurrentAppearance(APPEARANCE.LIGHT);
      }
    } else {
      if (defaultAppearance === APPEARANCE.LIGHT) {
        setCurrentAppearance(APPEARANCE.DARK);
      }
      if (defaultAppearance === APPEARANCE.DARK) {
        setCurrentAppearance(APPEARANCE.LIGHT);
      }
    }
  };

  const hasLocalAppearance =
    currentAppearance === APPEARANCE.LIGHT ||
    currentAppearance === APPEARANCE.DARK;

  const isCommunityDarkOrLightAppearance = (type: string) => {
    if (hasLocalAppearance) {
      return currentAppearance === type;
    }
    return defaultAppearance === type;
  };

  const isDarkAppearance = isCommunityDarkOrLightAppearance(APPEARANCE.DARK);

  const isLightAppearance = isCommunityDarkOrLightAppearance(APPEARANCE.LIGHT);

  return {
    currentAppearance,
    setAppearance,
    toggleAppearance,
    hasLocalAppearance,
    isDarkAppearance,
    isLightAppearance,
  };
};
