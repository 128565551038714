import {
  fetchRequest,
  reactQueryDelete,
  reactQueryPost,
  reactQueryPut,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const create = ({ formData, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.create(), {
    method: "POST",
    body: JSON.stringify(formData),
    ...fetchRequestOptions,
  });

const update = ({ id, formData }) =>
  reactQueryPut(internalApi.liveStreams.update({ id }), formData);

const goLive = ({ id, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.goLive({ id }), {
    method: "PUT",
    ...fetchRequestOptions,
  });

const downloadParticipantsCSV = ({ id, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.downloadParticipantsCSV({ id }), {
    method: "GET",
    ...fetchRequestOptions,
  });

const chatTranscript = ({ id, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.chatTranscript({ id }), {
    method: "GET",
    ...fetchRequestOptions,
  });

const join = ({ id, role, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.join({ id }), {
    method: "POST",
    body: JSON.stringify({ role }),
    ...fetchRequestOptions,
  });

const leave = ({ id, participantId, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.leave({ id, participantId }), {
    method: "DELETE",
    ...fetchRequestOptions,
  });

const addCohosts = ({ roomId, formData, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.addCohosts({ roomId }), {
    method: "POST",
    body: JSON.stringify(formData),
    ...fetchRequestOptions,
  });

const addModerator = ({ roomId, formData, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.addModerator({ roomId }), {
    method: "POST",
    body: JSON.stringify(formData),
    ...fetchRequestOptions,
  });

const removeModeratorFromRoom = ({
  id,
  roomId,
  formData,
  ...fetchRequestOptions
}) =>
  fetchRequest(
    internalApi.liveStreams.removeModeratorFromRoom({ id, roomId }),
    {
      method: "DELETE",
      body: JSON.stringify(formData),
      ...fetchRequestOptions,
    },
  );

const removeCohost = ({ id, roomId, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.removeCohost({ id, roomId }), {
    method: "DELETE",
    ...fetchRequestOptions,
  });

const changeRole = ({ roomId, participantId, role, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.changeRole({ roomId, participantId }), {
    method: "PATCH",
    body: JSON.stringify({ role }),
    ...fetchRequestOptions,
  });

const removeParticipant = ({ roomId, participantId, peerId }) =>
  reactQueryPost(
    internalApi.liveStreams.removeParticipant({
      roomId,
      participantId,
    }),
    {
      peerId,
    },
  );

const endStream = ({ id, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.endStream({ id }), {
    method: "DELETE",
    ...fetchRequestOptions,
  });

const endStreamDelayed = ({ id, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.liveStreams.endStreamDelayed({ id }), {
    method: "DELETE",
    ...fetchRequestOptions,
  });

const leaveFeedback = ({
  id,
  participantId,
  formData,
  ...fetchRequestOptions
}) =>
  fetchRequest(internalApi.liveStreams.leaveFeedback({ id, participantId }), {
    method: "POST",
    body: JSON.stringify(formData),
    ...fetchRequestOptions,
  });

const createRecorder = ({ id, recording_key }) =>
  reactQueryPost(internalApi.liveStreams.createRecorder({ id }), {
    recording_key,
  });

const deleteRoomRecording = ({ roomId, id }) =>
  reactQueryDelete(internalApi.liveStreams.deleteRoomRecording({ roomId, id }));

export const liveStreamApi = {
  create,
  update,
  goLive,
  downloadParticipantsCSV,
  chatTranscript,
  join,
  leave,
  addCohosts,
  removeCohost,
  changeRole,
  endStream,
  endStreamDelayed,
  leaveFeedback,
  createRecorder,
  deleteRoomRecording,
  removeParticipant,
  addModerator,
  removeModeratorFromRoom,
};
